import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { observer } from 'mobx-react';
import { action, observable } from 'mobx';

import { Routing } from './AppConstants';
import { AirportsListPage } from './pages/AirportsListPage/AirportsListPage';
import { OrderResultPage } from './pages/OrderResultPage/OrderResultPage';
import { ConfigurationPage } from './pages/ConfigurationPage/ConfigurationPage';
import { applyDecorators } from './helpers/Decorator';
import { AirportDto } from './models/WebApiModels';
import { LocalStorageGet, LocalStorageSet } from './helpers/Helpers';
import HomePage from './pages/HomePage/HomePage';
import { Loading } from './components/Loading';
import { loaderStore } from './stores/LoaderStore';

import './scss/App.scss';

const KEY_AIRPORT = 'airportCode';

@observer
export default class App extends React.Component {
    @observable
    private _pos?: AirportDto | null;

    constructor(props: {}) {
        super(props);
        applyDecorators(this);
    }

    componentDidMount(): void {
        this._pos = LocalStorageGet<AirportDto>(KEY_AIRPORT);
    }

    render(): React.ReactNode {
        return (
            <>
                <Loading loading={loaderStore.appLoader.isPending} />
                {this._pos ? <HomePage airport={this._pos} /> : <AirportsListPage onSelect={this._onPositionSet} />}
            </>
        );
    }

    @action
    private _onPositionSet = (position: AirportDto) => {
        this._pos = position;
        LocalStorageSet<AirportDto>(KEY_AIRPORT, position);
    };
}

export const AppRouter = () => {
    return (
        <Routes>
            <Route path={Routing.Home} element={<App />} />
            <Route path={Routing.Result} element={<OrderResultPage />} />
            <Route path={Routing.Configuration} element={<ConfigurationPage />} />
            <Route path="*" element={<Navigate replace to={Routing.Home} />} />
        </Routes>
    );
};
